@font-face {
  font-family: "Golos";
  src:
    local("Golos"),
    url("../assets/fonts/Golos-Text-VF/golos-text_vf.woff") format("woff"),
    url("../assets/fonts/Golos-Text-VF/golos-text_vf.woff2") format("woff2");
  font-style: normal;
}
@font-face {
  font-family: "Golos UI";
  src:
    local("Golos UI"),
    url("../assets/fonts/Golos-UI-VF/golos-ui_vf.woff2") format("woff2"),
    url("../assets/fonts/Golos-UI-VF/golos-ui_vf.woff") format("woff");
  font-style: normal;
}
