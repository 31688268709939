@use '/src/styles/' as *;

.loader-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh; 
}
:root {
    --loader-size: 100px;
  }

.loader {
    width: var(--loader-size);
    height:var(--loader-size);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: $main-200  $main-200 transparent $main-200;
    box-sizing: border-box;
    animation: rotation 1.5s linear infinite;
    margin-top: -5%;
  }
  .loader::after,
  .loader::before {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent $main-600 $main-600;
    width: calc(var(--loader-size) - 15px);
    height: calc(var(--loader-size) - 15px);
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.7s linear infinite;
    transform-origin: center center;
  }
  .loader::before {
    width: calc(var(--loader-size) - 30px);
    height: calc(var(--loader-size) - 30px);
    border-color: $main-400 $main-400 transparent transparent;
    animation: rotation 2s linear infinite;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
      